.whatWeDo{
    background-image: linear-gradient(to right bottom,#ff004c, #91002b);
    padding: 50px 0;
}

.whatWeDo .container{
    width: 80%;
    margin: 0 auto;
    transition: 1s;
    color: white;
}

.whatWeDo .whatWeDoHead h2{
    font-size: 4rem;
    line-height: 4.5rem;
    margin-bottom: 20px;
}

.whatWeDo .whatWeDoHead p{
    font-size: 1.4rem;
}

.whatWeDo .work{
    color: white;
    text-decoration: none;
    margin-block: 20px;
    padding-block: 10px;
    display: flex;
    flex-direction: column;
    position: relative;
}

.whatWeDo .work:first-child{
    z-index: 5;
}
.whatWeDo .work:nth-child(2){
    z-index: 4;
}
.whatWeDo .work:nth-child(3){
    z-index: 3;
}
.whatWeDo .work:nth-child(4){
    z-index: 2;
}

.whatWeDo .work h2{
    font-size: 3rem;
    white-space: nowrap;
}

.whatWeDo .work:hover .floating_img{
    display: block;
}


.whatWeDo .floating_img{
    position: absolute;
    top: 70%;
    width: 100px;
    display: none;
    transition: all .01s;
    transform: scale(0);
    animation: popup .2s ease-in forwards, whatWeDoAnim2 1s ease-in-out .3s infinite alternate;
    /* mix-blend-mode:multiply; */
}

@keyframes popup {
    0%{transform: scale(0);}
    100%{transform: scale(1);}
}

@keyframes whatWeDoAnim2 {
    0%{transform: translateY(5px);}
    100%{transform: translateY(0);}
}

.whatWeDo .circleLink{
    display: none;
}

@media screen and (max-width: 900px){
    .whatWeDo{
        padding: 20px 0;
    }

    .whatWeDo .container{
        width: 90%;
    }
    .whatWeDo .whatWeDoHead h2{
        font-size: 3rem;
        line-height: 3rem;
        margin-bottom: 15px;
    }

    .whatWeDo .container .work h2{
        font-size: 2.2rem;
    }

    .whatWeDo .circleLink{
        margin-left: 10px;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        text-decoration: none;
        background-color: white;
        color: #e31d77;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .whatWeDo .work:hover .floating_img,
    .whatWeDo .container .work p{
        display: none;
    }

    .circleLink{
        position: absolute;
        right: 0;
    }
}

@media screen and (max-width: 650px){
    .whatWeDo .whatWeDoHead h2{
        font-size: 2.5rem;
        margin-bottom: 40px;
    }

    .whatWeDo .work{
        /* padding-block: 0px; */
    }
    
    .whatWeDo .container .work h2{
        font-size: 1.7rem;
        white-space:break-spaces;
        line-height: 2rem;
    }
    .whatWeDo .whatWeDoHead p{
        font-size: 1.2rem;
    }
}


@media screen and (max-width: 400px){
    .whatWeDo .container .work h2{
        font-size: 1.5rem;
        line-height: 1.7rem;
    }
}