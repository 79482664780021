@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

:root{
    --color-primary : orangered;
}

html{
    scroll-behavior: smooth;
}

::-webkit-scrollbar{
    width: .5vw;
}

::-webkit-scrollbar-track{
    background-color: white;
    outline: 1px solid #dadada;
}

::-webkit-scrollbar-thumb{
    background-color: #afafaf;
    border-radius: 20px;
}

.dotCursor{
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #d91b57;
    position: fixed;
    z-index: 11;
    transition: .3s ease-out;
}

.dotCursor:hover{
    transform: scale(0);
}


@media screen and (max-width: 780px) {
    .dotCursor{
        display: none;
    }
}

@media (pointer:coarse){
    .dotCursor{
        display: none;
    }
}