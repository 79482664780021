.footer{
    background-image: linear-gradient(to right,#d81c55, #120B3a);
    position: relative;
    padding: 70px 0 130px;
}

.forGradiant{
    background-image: url('./f2.jpeg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    mix-blend-mode:multiply;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.footer .container{
    opacity: 1;
    color: white;
    mix-blend-mode:lighten;
    width: 80%;
    margin: 0 auto;
    display: flex;
    gap: 30px;
}

.footer .social{
    display: none;
}

@media screen and (max-width: 900px){
    .footer{
        padding-block: 70px;
    }

    .footer .container{
        width: 90%;
        flex-wrap: wrap;
    }

    .footer .container>*{
        width: 45%;
    }
    
    .footer .social{
        width: 90%;
        margin: 20px auto;
        display: flex;
        align-items: center;
        gap: 5px;
    }

    .footer .social a{
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background-color: white;
        mix-blend-mode:lighten;
        transition: all .2s;
        color: black;
    }
}

@media screen and (max-width: 450px){
    .footer .container>*{
        width: 95%;
    }
}