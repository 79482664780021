.blog{
    padding: 50px 0 0;
    overflow: hidden;
    gap: 100px;

}
.blog .contactUs{
  padding: 80px 0 20px;
}
.blog .B {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80%;
  margin: 0 auto;
  border-bottom: 1px rgb(211, 211, 211) solid;
  
}
.blog .B span{
  font-size: 4rem;
  font-weight: bold;
 padding-left: 50px;
 cursor: pointer;
 display: block;
 margin-block: 10px;
 transition: .5s;

}

.blog .B p{
  font-size: 1.3rem;
  line-height: 1.7rem;
  width: 60%;
  
}

.blog .sha {
  border-bottom: 1px rgb(211, 211, 211) solid;
}



.blog .content-left{
        flex: 6;   
        display:center;
        padding-left: 10px;
    }
    
 .blog .content-left  img{
        width: 100%;
        height: 415px;
        border: 0px solid #d81c55;
        cursor: pointer;
        padding-left: 110px;
    }
    
    .blog img:hover{
      transition: .2s;
      transform: scale(.98);
  }

 
  .blog .content-leftt {
    padding-top: 250px;
  }

  .blog .content-leftt .iimg{
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-row-gap: 100px;
    padding-left: 120px;
    gap: 30px;
    cursor: pointer;
}

.blog .content-leftt .iimg li{
  list-style-type: none;
}

.blog .content-leftt .iimg img{
       width: 350px;
       height: 320px;

 }
 
 .blog .content-leftt h3{
        font-weight: bold;
        font-size: 1.3rem;
 } 
  
 .blog .content-leftt h2{
  padding-left: 550px;
} 

.blog  span{
        font-weight: bold;
        font-size: 2rem;
        width: 20%;
        padding-left: 350px;
        
    }
.blog .content-left p{
    
    text-align:justify;
    font-size: 0.9rem;
    padding: 10px 16px;
    transition: 0.3s;
    border-radius: 5px;
} 

.blog .content-left p h5{
  color: #d81c55;
  font-size: 0.9rem;
}


.blog .content-left .bot {
  display: flex;
  
}
.blog .content-left .bot h3{
  padding: 10px;
  padding-left: 110px;
  font-size: 1.6rem;
  color: black;
  font-weight: bold;
  cursor: pointer;
  
}
.blog .content-leftt .bot h3{
  color: black;
  
}








.blog .content-left .container-right{
    flex: 3;
    right: 0px;
    width: 300px;
    border-bottom: 1px rgb(211, 211, 211) solid;
    
   }

.blog .content-left .container-right {
    
    
    transition: 0.3s;
    border-radius: 5px;
  }   
 

.blog .content-left .container-right p{
   text-align:justify;
   font-size: 0.9rem;
   
}
.blog .content-left .container-right h3{
  border-bottom: 1px rgb(211, 211, 211) solid;
  color: #d81c55;
  font-size: 1.4rem;
  font-weight: bold;
  padding-left: 10px;
}
.blog .content-left .container-right h4{

  color: #d81c55;
}







  @media screen and (max-width: 900px) {
    .blog .B{
        width: 90%;
    }
}

@media screen and (max-width: 768px) {
    .blog .B{
        display: block;
        padding-block: 10px;
    }
    .blog .content-leftt {
      padding-top: 80px;
    }
    
.blog .content-left{
  flex: 6;   
  display:center;
  padding-left: 0px;
}
    .blog .content-left .bot{
      padding-block: 0px;
      width: 90%;
    }
    .blog  span{
      width: 100%;
      padding-left: 70px;
    }
    .blog .content-left img {
      width: 100%;
      padding-left: 0px;
    }

    .blog .content-leftt .iimg {
      display: block;
      padding-left: 0px;
    }
    .blog .content-leftt .iimg img{
      width: 100%;
    }
    .blog .content-leftt .iimg h3{
      padding-left: 20px;
    }
  }
  .blog .content-left {
    display:flex;
    flex-wrap:wrap; /* this */
    gap:10px;
   
  }
  
