.brand{
    padding: 100px 0 0;
    overflow: hidden;
}


.Blog2 .detail{
    text-align: justify;
    margin: 0 auto;  
    padding-top: 40px;
    padding-left: 350px;
}

.content-left{
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    padding-top: 30px;
    
}
.Blog2 .content-left .container-right{
    flex: 3;
    right: 0px;
    width: 300px;
    border-bottom: 1px rgb(211, 211, 211) solid;
    padding-left: 80px;
    padding: 60px;
    background-color: whitesmoke;
   }
.content-left .container-right h3{
    font-size: 2.5rem;
}
.content-left  img{
    width: 670px;
    height: 600px;
    border: 0px solid #d81c55;
    cursor: pointer;
    
}   



.Blog2 .content-leftt .iimg{
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-row-gap: 100px;
    padding-left: 120px;
    gap: 30px;
    cursor: pointer;
}
.Blog2 .content-leftt .iimg img{
       width: 350px;
       height: 300px;

 }
 .Blog2 .content-leftt h3{
        font-weight: bold;
        font-size: 1.3rem;
 } 
 .Blog2 .content-leftt .bot h3{
    color:#d81c55 ;
    
  }

.Blog2 h2{
    padding-left: 540px;
}  



@media only screen and (max-width: 768px) {
    .Blog2 .detail {
    width: 100%;
    padding-left: 0px;
    
    }
    .Blog2 .content-leftt .iimg {
        display: block;
        padding-left: 0px;
       
      }
      .Blog2 .content-leftt .iimg img{
        width: 100%;
      }
      .Blog2 .content-leftt .iimg h3{
        padding-left: 20px;
      }
}