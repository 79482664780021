.nav{
    position: fixed;
    width : 100%;
    height: 80px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    z-index: 10;
    background-color: rgba(255, 255, 255,.95);
    backdrop-filter: blur(5px);
}

.nav .container{
    height: 100%;
    width: 80%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.nav .logo{
    width: 100px;
}

.navLinks{
    display: flex;
    gap: 30px;
}

.navLinks a{
    text-decoration: none;
    color: rgb(43, 43, 43);
    font-weight: 600;
    padding: 0 5px;
    border-bottom: 2px transparent solid;
    letter-spacing: .5px;
    position: relative;
}

.navLinks a::after{
    content: "";
    height: 3px;
    width: 100%;
    background-image: linear-gradient(to right,#ff6704, #e31d77);
    position: absolute;
    left: 0;
    bottom: -5px;
    transition: .4s;
    transform: scale(0);
}

.navLinks a:hover::after{
    transform: scale(1);
}

.menuBar{
    display: none;
    cursor: pointer;
}

.menuBar span{
    width: 28px;
    height: 2px;
    display: block;
    background-color: rgb(217,27,87);
}

/* .darkMode{
    width: 45px;
    height: 20px;
    border: solid 1px;
    border-radius: 20px;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: 2s;
}

.darkModeBall{
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: black;
} */




@media screen and (max-width: 960px) {
    .nav .logo{
        width: 60px;
    }

    .nav .container{
        width: 90%;
    }
    .navLinks{
        position: absolute;
        top: 70px;
        left: 0;
        width: 100%;
        height: 0;
        overflow: hidden;
        font-size: 1.5rem;
        flex-direction: column;
        background-color: white;
        align-items: center;
        transition: .5s ease-out;
    }

    .navLinks a:first-of-type{
        margin-top: 50px;
    }

    .closeNavMenu{
        display: block;
        position: absolute;
        right: 10vw;
        top: 10vh;
        font-size: 3rem;
        cursor: pointer;
    }
    .menuBar{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 8px;
    }

    .line1, .line3{
        transform: rotate(0);
        transition: .5s;
    }

    .closeBar .line2{
        visibility: hidden;
    }

    .closeBar .line1{
        transform: rotate(405deg);
        transform-origin:left;
    }

    .closeBar .line3{
        transform: rotate(-405deg);
        transform-origin:left;
    }

    .showLinks{
        height: 100vh;
    }
}
